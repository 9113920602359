<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getOrgDomain } from '@/utils'
import common from "@/utils/common";
import { mapGetters} from "vuex"
import { Dialog } from 'vant'

export default {
  name: "App",
  data() {
    return {
      timerToken: null
    }
  },
  computed: {
    ...mapGetters({
      isCheckToken: "user/getIScheckToken",
    })
  },
  watch:  {
    isCheckToken(to) {
       if (to) {
         this.runTimeCheckToken()
       }
    }
  },
  created() {
    this.domain = window.location.host;
    // this.domain = "wx-aboh.tengface.cn"
    if (process.env.NODE_ENV == "development") {
      this.domain = process.env.VUE_APP_DOMAIN_URL;
    }
    //获取token , 路由地址为/时无法取到token
    let token = this.$route.query.token;
    if (location.hash.indexOf("token") > -1) {
      token = location.hash.split("token=").pop();
    }
    if (location.search.indexOf("token") > -1) {
      token = common.getQueryString("token");
    }
    if (token) {
      localStorage.setItem("token", token);
    }
    
    //判断是否是harmonyOS
    let moblie = this.$route.query.moblie || common.getQueryString("moblie")
    if (moblie == "harmonyOS") {
      localStorage.setItem("tengface_moblie","harmonyOS");
    }

    this.getOrgInfo();
    this.getOrgBaseInfo();
    console.log("token====================");
    this.runTimeCheckToken()
  },
  methods: {
    runTimeCheckToken() {
      let token2 = window.localStorage.getItem("token")
      if (token2 && !this.timerToken) {
        console.log("登录校验")
        this.timerToken = setInterval(() => {
          this.checkTokenValid()
        }, 10000);
      }
    },
    checkTokenValid() {
      if (localStorage.getItem('token')) {
        this.$api.post('/user/index/checkToken')
          .then(res => {
            if (res.data.code == 100068 || res.data.code == 100067) {
              this.timerToken && clearInterval(this.timerToken)
              this.timerToken = null
              localStorage.removeItem('token')
              location.reload()
            }
          })
      }
    },
    getOrgBaseInfo() {
      this.$api
        .get(`/common/index/getWebBaseInfo?key=${getOrgDomain()}`)
        .then((res) => {
          if (res.data.code == 200) {
            document.title = res.data.data.web_company_name || "网校";
            localStorage.setItem(
              "web_contact_number",
              res.data.data.web_contact_number || "XXX-XXX-XXX"
            );
            document.querySelectorAll("link[rel*='icon']").forEach((item) => {
              item.href = res.data?.data?.web_header_icon;
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 域名获取商户id
    async getOrgInfo() {
      return new Promise((resolve, reject) => {
        const data = {
          // domain: this.domain,
          //  domain: "wx-adia.shkt.online",
          //domain: 'wx-adia.shanhuketang.com'
          domain: getOrgDomain()
        };
        this.$api
          .post("/common/index/getOrgInfo", data)
          .then((res) => {
            if (res.data.code == 200) {
              this.$store.commit("user/setMoFangUrl", res.data.data.link);
              localStorage.setItem("tengface_mofang_url", res.data.data.link);
              this.$store.commit("user/setOrgId", res.data.data.uid);
              localStorage.setItem('tengface_is_show', res.data.data.is_show)
              if (
                `${localStorage.getItem("tengface_org_id")}` !==
                `${res.data.data.uid}`
              ) {
                localStorage.setItem("tengface_org_id", res.data.data.uid);
                // uid更新需要刷新重新加载数据
                location.reload();
              }
              // if(res.data.data.link && this.$route.name !== 'CourseHome' && this.$route.name !== 'zhanghaomimadl') {
              // 	// 判断用户是否使用魔方网校主页作为网校首页
              // 	this.$router.push({ name: 'CourseHome', query: { requestUrl: res.data.data.link } })
              // }
            } else if(res.data.code === 100068){
              // 校验机构状态是否正常
              Dialog.alert({
                title: '提示',
                message: '该机构已被禁用，直播相关功能限制使用',
                showConfirmButton: false
              })
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
